//import '_libraries/jquery/3.4.1/jquery.min.js'
//import '_libraries/bootstrap/4.5.3/bootstrap.min.js'

const body = document.getElementById('body');
const combinations = ['combination_1', 'combination_2', 'combination_3', 'combination_4'];

changeCombination();

function changeCombination() {
    const combination = Math.floor(Math.random()*combinations.length);

    const combinationValue = combinations[combination];

    //console.log('combination: ' + combinationValue);
    
    for( var i = 0; i < combinations.length; i++){ 
        if(combinations[i] === combinationValue){
            combinations.splice(i, 1); 
        }
    }

    if (combinations.length === 0){

        const lastCombination = combinationValue;

        //console.log('last combination: ' + lastCombination);

        combinations.push('combination_1', 'combination_2', 'combination_3', 'combination_4');

        for( var i = 0; i < combinations.length; i++){ 
            if(combinations[i] === lastCombination){
                combinations.splice(i, 1); 
            }
        }
    }

    body.className = '';
    body.classList.add(combinationValue);
    setTimeout(changeCombination, 20000);
    
    //console.log(combinations);
 }
const aboutMe = document.getElementById('over-mij');
const arrowDown = document.getElementById('arrow-down');
const link = document.getElementById('link');

const bounding = aboutMe.getBoundingClientRect();

function aboutMeInViewport() {
    const bounding = aboutMe.getBoundingClientRect();
    
    if (bounding.top >= 0 && bounding.left >= 0 && bounding.right <= (window.innerWidth || document.documentElement.clientWidth) && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
        arrowDown.style.transform = "rotate(180deg)";
        link.href = "#body";
        //console.log('About me is in the viewport!');
    } 
    else {
        arrowDown.style.transform = "rotate(360deg)";
        link.href = "#over-mij";
        console.log('About me is not in the viewport!');
    }
}

window.addEventListener('scroll', function() {
    //console.log('scrolling');
    aboutMeInViewport();
});
let copyText = document.querySelector('.footer--left-email');
let copiedText = document.querySelector('.footer--left-email-copied');
copyText.querySelector('svg').addEventListener('click', function () {
    let clipboard = copyText.querySelector('p.footer--left-email-text > a').innerHTML;
    navigator.clipboard.writeText(clipboard).then(() => {
        //console.log('Content copied to clipboard');
      },() => {
        //console.error('Failed to copy');
      });
      copiedText.classList.add('active');
      setTimeout(function () {
        copiedText.classList.remove('active');
    }, 2500);
});
//If reactivated don't forget to add @ before
//Reason why @ isnt't used when commenting is because gulp still imports the file when using @ in comment
	//import '_components/test.js'

	document.querySelectorAll('a[href^="#"]').forEach(anchor => {
		anchor.addEventListener('click', function (e) {
			e.preventDefault();
			document.querySelector(this.getAttribute('href')).scrollIntoView({
				behavior: 'smooth'
			});
		});
	});